const routes = [
  // Website
  {
    path: '/:id',
    params: true,
    meta: {website: true},
    component: () => import('@/pages/WebsiteClientView.vue')
  },
  {
    path: '/404',
    component: () => import('@/pages/404.vue')
  },
  // Unrestricted - Page 404
  {
    path: '/:catchAll(.*)*',
    component: () => import('@/pages/404.vue')
  }
]

export default routes
