<script setup>
import { defineAsyncComponent} from 'vue'
import GlobalDialog from '@/components/GlobalDialog.vue';

const GlobalNotify = defineAsyncComponent(() => import('@/components/GlobalNotify.vue'))
const GlobalNotifyLoading = defineAsyncComponent(() => import('@/components/GlobalNotifyLoading.vue'))

</script>

<template>
  <div class="min-h-screen">
    <global-notify />
    <global-dialog />
    <global-notify-loading />

    <!-- Main Content View -->
    <router-view v-slot="{ Component }">
      <transition name="fade">
        <component :is="Component" />
      </transition>
    </router-view>
  </div>
</template>
