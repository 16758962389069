// useRoute
import {createRouter, createWebHistory} from 'vue-router'
import routes from '@/router/routes.js'
import {useWebsiteStore} from '@/stores/useWebsiteStore.js';

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return savedPosition || new Promise((resolve) => {
      setTimeout(() => resolve({top: 0}), 300)
    })
  }
})


router.beforeEach(async (to, from, next) => {
  next()
})

export default router
