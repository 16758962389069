<script setup>
import {computed, defineAsyncComponent, ref} from 'vue'
import {useGlobalStore} from '@/stores/useGlobalStore.js'
import {CheckCircleIcon, ExclamationCircleIcon} from '@heroicons/vue/24/outline'
import BaseDialog from '@/components/BaseDialog.vue';

const ButtonPrimary = defineAsyncComponent(() => import('@/components/ButtonPrimary.vue'))
const ButtonSecondary = defineAsyncComponent(() => import('@/components/ButtonSecondary.vue'))

const useGlobal = useGlobalStore()
const focus = ref(null)

const isOpen = computed({
  get() {
    return useGlobal.dialog.show
  },
  set(value) {
    useGlobal.dialog.show = value
  }
})

</script>

<template>
  <base-dialog
    v-model="isOpen"
    width="w-full max-w-sm"
  >
    <!-- Success icon -->
    <div
      v-if="useGlobal.dialog.type === 'success'"
      class="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-600/20"
    >
      <CheckCircleIcon
        aria-hidden="true"
        class="h-8 w-8 text-green-600"
      />
    </div>

    <!-- Destructive icon -->
    <div
      v-if="useGlobal.dialog.type === 'destructive'"
      class="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-red-600/20"
    >
      <ExclamationCircleIcon
        aria-hidden="true"
        class="h-8 w-8 text-red-500"
      />
    </div>

    <div
      v-if="useGlobal.dialog.type === 'warning'"
      class="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-yellow-600/20"
    >
      <ExclamationCircleIcon
        aria-hidden="true"
        class="h-8 w-8 text-yellow-500"
      />
    </div>

    <!-- Heading -->
    <div class="mt-3 text-center sm:mt-5">
      <div class="text-lg/6 font-medium text-balance">
        {{ useGlobal.dialog.title }}
      </div>

      <div class="mt-2">
        <p class="text-sm text-gray-600 dark:text-gray-400 text-balance">
          {{ useGlobal.dialog.text }}
        </p>
      </div>
    </div>

    <div
      class="mt-6 space-y-4"
    >
      <button-primary
        v-if="useGlobal?.dialog.action?.name"
        :destructive="useGlobal.dialog.type === 'destructive'"
        :loading="useGlobal.isPending"
        @click="useGlobal.confirmDialog()"
      >
        {{ useGlobal.dialog.action.name }}
      </button-primary>

      <button-secondary
        ref="cancelButtonRef"
        class="flex justify-center"
        @click="useGlobal.closeDialog()"
      >
        {{ useGlobal.dialog.dismiss ? useGlobal.dialog.dismiss : 'Close' }}
      </button-secondary>
    </div>

    <div
      v-if="useGlobal.dialog.subtext"
      class="mt-4"
    >
      <p class="text-xs text-gray-600 dark:text-gray-400 text-center">
        {{ useGlobal.dialog.subtext }}
      </p>
    </div>
  </base-dialog>
</template>
