const production_rate = {
  name: '',
  key: '',
  value: 0,
  type: '$',
  unit: 'count',
  qty: 0
}

const template = {
  name: '',
  key: '',
  type: '',
  production_rates: []
};

const exterior_template = {
  name: 'Exterior Painting',
  key: '',
  type: 'exterior',
  production_rates: [
    {
      key: 1,
      name: 'SqFt',
      value: 1.2,
      type: '$',
      unit: 'sqft',
      qty: 0
    }, {
      key: 2,
      name: 'Color Increase',
      value: 10,
      type: '%',
      unit: 'sqft',
      qty: 0
    }, {
      key: 3,
      name: 'Difficulty',
      value: 10,
      type: '%',
      unit: 'sqft',
      qty: 0
    }, {
      key: 4,
      name: 'Condition',
      value: 10,
      type: '%',
      unit: 'sqft',
      qty: 0
    }, {
      key: 5,
      name: 'Door',
      value: 75,
      type: '$',
      unit: 'count',
      qty: 0
    }, {
      key: 6,
      name: 'Garage Door',
      value: 100,
      type: '$',
      unit: 'count',
      qty: 0
    }, {
      key: 7,
      name: 'Window',
      value: 45,
      type: '$',
      unit: 'count',
      qty: 0
    }, {
      key: 8,
      name: 'Window Sash',
      value: 45,
      type: '$',
      unit: 'count',
      qty: 0
    }, {
      key: 9,
      name: 'Shutter',
      value: 55,
      type: '$',
      unit: 'count',
      qty: 0
    }
  ]
};

const interior_template = {
  name: 'Interior Painting',
  key: '',
  type: 'interior',
  production_rates: [
    {
      key: 1,
      name: 'SqFt',
      value: 0.8,
      type: '$',
      unit: 'sqft',
      qty: 0
    }, {
      key: 2,
      name: 'Ceiling',
      value: 1,
      type: '$',
      unit: 'sqft',
      qty: 0
    }, {
      key: 3,
      name: 'Trim',
      value: 1.2,
      type: '$',
      unit: 'sqft',
      qty: 0
    }, {
      key: 4,
      name: 'Door',
      value: 60,
      type: '$',
      unit: 'count',
      qty: 0
    }, {
      key: 5,
      name: 'Window',
      value: 35,
      type: '$',
      unit: 'count',
      qty: 0
    }, {
      key: 6,
      name: 'Window Sash',
      value: 35,
      type: '$',
      unit: 'count',
      qty: 0
    }, {
      key: 7,
      name: 'Feature',
      value: 35,
      type: '$',
      unit: 'count',
      qty: 0
    }
  ]
};

const structure_options = [
  'Contractor', 'Subcontractor', 'Independent', 'Solo'
]

const position_options = [
  'Subcontractor', 'Prepper', 'Painter', 'Estimator', 'Project Manager', 'Other'
]

const rate_options = [
  '$15 - $30 / hour', '$30 - $50 / hour', '$50+ / hour', 'Per Job Basis', 'Other'
]

const service_options = [
  'commercial', 'residential', 'interior', 'exterior', 'repaint', 'new construction', 'cabinets', 'repairs'
]

const directory = {
  established: new Date().getFullYear(),
  structure: '',
  services: [],
  pay_rates: [],
  status: '',
  positions: [],
  employment: '',
  isPublic: true
};

const attachment = {
  key: '',
  url: '',
  title: '',
  filename: '',
  type: 'application/pdf'
}

const email_template = {
  key: '',
  subject: '',
  message: '',
  order: '',
  title: ''
}

const address = {
  street: '',
  unit: '',
  city: '',
  state: '',
  zip: '',
  country: '',
  formatted: '',
  lat: null,
  long: null
}

const company = {
  id: '',
  createdAt: 0,
  admin: [],
  license: '',
  logoUrl: '',
  geoHash: '',
  contractors: [],
  requests: {
    sent: [],
    received: []
  },
  rating: {
    count: 0,
    total: 0
  },
  estimateNumberRef: 100,
  invoiceNumberRef: 100,
  contact: {
    name: '',
    phone: '',
    email: '',
    address: address,
    website: '',
    tradebox: '',
    yelp: '',
    instagram: '',
    facebook: '',
    showYelpReviews: true,
    businessHours: {
      days: 'Week days',
      hours: '9am - 5pm'
    }
  },
  production: {
    ext: exterior_template,
    int: interior_template
  },
  plan: {
    type: 'free',
    provider: '',
    will_lapse: false,
    has_downgraded: false,
    period_end: 0,
    stripe: {
      customer_uid: '',
      subscription_uid: ''
    },
    stripe_connect: {
      account_uid: '',
      details_submitted: false,
      charges_enabled: false
    }
  },
  directory: directory,
  currency: 'usd',
  webBuilderUrl: '',
  version: 'v4'
}

const user = {
  uid: '',
  name: '',
  email: '',
  createdAt: 0,
  myCompany: '',
  last_sign_in: 0,
  platform: {
    platform: '',
    capacitor: ''
  },
  version: 'v4'
}

const contact = {
  id: '',
  contactId: '',
  uid: '',
  createdAt: null,
  email: '',
  name: '',
  phone: '',
  company: '',
  address: {
    formatted: '',
    streetNumber: '',
    streetName: '',
    street: '',
    state: '',
    countryShort: '',
    zip: '',
    country: '',
    long: null,
    lat: null,
    link: '',
    city: ''
  },
  stripe: {id: ''}
}

const lineItem = {
  key: '',
  type: '',
  label: '',
  description: '',
  applyTax: [],
  unit_amount_wholesale: null,
  unit_amount: null,
  qty: null
}

const tax = {
  key: '',
  desc: '',
  rate: null
}

const room = {
  key: '',
  desc: '',
  notes: '',
  qty: 1,
  cost: 0,
  length: 0,
  width: 0,
  height: 0,
  production_rates: [],
  counts: [],
  scope: {
    isWalls: true,
    isDoors: false,
    isCeiling: false,
    isTrim: false,
    isCrown: false
  }
}

const rooms = [
  {
    key: 'livingRoom',
    desc: 'Living room',
    cost: 0,
    qty: 0,
    length: 16,
    width: 20,
    height: 8,
    production_rates: [],
    counts: [],
    scope: {
      isWalls: true,
      isDoors: false,
      isCeiling: false,
      isTrim: false,
      isCrown: false
    },
    notes: ''
  }, {
    key: 'bedRoom',
    desc: 'Bedroom',
    cost: 0,
    qty: 0,
    length: 12,
    width: 14,
    height: 8,
    production_rates: [],
    counts: [],
    scope: {
      isWalls: true,
      isDoors: false,
      isCeiling: false,
      isTrim: false,
      isCrown: false
    },
    notes: ''
  }, {
    key: 'primaryBedRoom',
    desc: 'Primary Bedroom',
    cost: 0,
    qty: 0,
    length: 14,
    width: 16,
    height: 8,
    production_rates: [],
    counts: [],
    scope: {
      isWalls: true,
      isDoors: false,
      isCeiling: false,
      isTrim: false,
      isCrown: false
    },
    notes: ''
  }, {
    key: '1/2BathRoom',
    desc: '1/2 Bathroom',
    cost: 0,
    qty: 0,
    length: 5,
    width: 8,
    height: 8,
    production_rates: [],
    counts: [],
    scope: {
      isWalls: true,
      isDoors: false,
      isCeiling: false,
      isTrim: false,
      isCrown: false
    },
    notes: ''
  }, {
    key: '3/4BathRoom',
    desc: '3/4 Bathroom',
    cost: 0,
    qty: 0,
    length: 6,
    width: 10,
    height: 8,
    production_rates: [],
    counts: [],
    scope: {
      isWalls: true,
      isDoors: false,
      isCeiling: false,
      isTrim: false,
      isCrown: false
    },
    notes: ''
  }, {
    key: 'fullBathRoom',
    desc: 'Full Bathroom',
    cost: 0,
    length: 10,
    width: 12,
    height: 8,
    production_rates: [],
    counts: [],
    notes: '',
    scope: {
      isWalls: true,
      isDoors: false,
      isCeiling: false,
      isTrim: false,
      isCrown: false
    },
    qty: 0
  }, {
    key: 'office',
    desc: 'Office',
    cost: 0,
    qty: 0,
    length: 12,
    width: 12,
    height: 8,
    production_rates: [],
    counts: [],
    scope: {
      isWalls: true,
      isDoors: false,
      isCeiling: false,
      isTrim: false,
      isCrown: false
    },
    notes: ''
  }, {
    key: 'small',
    desc: 'Small Room',
    cost: 0,
    qty: 0,
    length: 12,
    width: 12,
    height: 8,
    production_rates: [],
    counts: [],
    scope: {
      isWalls: true,
      isDoors: false,
      isCeiling: false,
      isTrim: false,
      isCrown: false
    },
    notes: ''
  }, {
    key: 'medium',
    desc: 'Medium Room',
    cost: 0,
    qty: 0,
    length: 14,
    width: 16,
    height: 8,
    production_rates: [],
    counts: [],
    scope: {
      isWalls: true,
      isDoors: false,
      isCeiling: false,
      isTrim: false,
      isCrown: false
    },
    notes: ''
  }, {
    key: 'large',
    desc: 'Large Room',
    cost: 0,
    qty: 0,
    length: 16,
    width: 20,
    height: 8,
    production_rates: [],
    counts: [],
    scope: {
      isWalls: true,
      isDoors: false,
      isCeiling: false,
      isTrim: false,
      isCrown: false
    },
    notes: ''
  }
]

const estimate = {
  createdAt: 0,
  id: '',
  reference_number: 0,
  type: 'general',
  user: {uid: ''},
  company: {id: ''},
  company_id: '',
  url: {
    original: '',
    short: '',
    short_id: ''
  },
  contact: contact,
  template,
  totalSqft: 0,
  labor: [],
  production: [],
  products: [],
  rooms: [],
  measurements: [],
  attachments: [],
  photos: {},
  total: 0,
  depositType: '$',
  deposit: 0,
  discountType: '$',
  discount: 0,
  version: 'v4',
  notes: '',
  status: 'draft',
  signature: null
}


const invoice = {
  createdAt: 0,
  id: '',
  reference_number: 0,
  type: 'general',
  user: {uid: ''},
  company: {id: ''},
  company_id: '',
  url: {
    original: '',
    short: '',
    short_id: ''
  },
  contact: contact,
  labor: [],
  products: [],
  attachments: [],
  photos: {},
  total: 0,
  discountType: '$',
  discount: 0,
  days_until_due: 7,
  notes: '',
  status: 'draft',
  stripe: {
    id: '',
    hosted_invoice_url: '',
    invoice_pdf: ''
  },
  version: 'v4'

}

const statuses = [
  'draft', 'pending', 'accepted', 'complete', 'canceled'
]

const lead = {
  createdAt: 0,
  id: '',
  company: {id: ''},
  contact,
  message: '',
  status: 'pending',
  version: 'v4'
}

const calendar_event = {
  id: '',
  uid: '',
  companyId: '',
  subContractorId: '',
  estimateId: '',
  title: '',
  details: '',
  date: '',
  endDate: '',
  time: '',
  endTime: '',
  allDay: false,
  dates: [], // "2023-10-10"
  type: {
    label: '',
    value: ''
  },
  contact,
  user: {name: ''},
  company: {
    email: '',
    phone: '',
    name: ''
  }
}

const notification = {
  id: '',
  companyId: '',
  title: '',
  body: '',
  href: '',
  badge: 1,
  createdAt: 0,
  devices: [],
  docId: '',
  type: ''
}

const colors = ['red', 'orange', 'amber', 'yellow', 'lime', 'green', 'emerald', 'teal', 'cyan', 'sky', 'blue', 'indigo', 'violet', 'purple', 'fuchsia', 'pink', 'rose']

const bgColors = ['bg-red-500', 'bg-orange-500', 'bg-amber-500', 'bg-yellow-500', 'bg-lime-500', 'bg-green-500', 'bg-emerald-500', 'bg-teal-500', 'bg-cyan-500', 'bg-sky-500', 'bg-blue-500', 'bg-indigo-500', 'bg-violet-500', 'bg-purple-500', 'bg-fuchsia-500', 'bg-pink-500', 'bg-rose-500']

const currency_options = ['USD', 'CAD', 'MXN', 'AUD', 'NZD', 'EUR']


export {
  attachment,
  address,
  company,
  contact,
  directory,
  estimate,
  invoice,
  calendar_event,
  production_rate,
  exterior_template,
  interior_template,
  email_template,
  lineItem,
  room,
  rooms,
  statuses,
  notification,
  user,
  tax,
  lead,
  structure_options,
  position_options,
  rate_options,
  service_options,
  currency_options,
  colors,
  bgColors
}


