import {cloneDeep} from 'lodash';
import {computed, ref} from 'vue';
import {firebaseAuth, getFirebaseData} from '@/apis/index.js';
import {acceptHMRUpdate, defineStore} from 'pinia';
import {getFirebaseCollection} from '@/apis/index.js';
import {website as websiteRef} from '@/utils/website.js';
import {updateWebsiteV2} from '@/utils/updateWebsiteV2.js';
import {user as userRef, company as companyRef} from '@/utils/constants.js';

export const useWebsiteStore = defineStore('website', () => {
  // State
  const user = ref(cloneDeep(userRef))
  const website = ref(cloneDeep(websiteRef))
  const company = ref(cloneDeep(companyRef))

  const isPremiumWeb = computed(() => ['pro_web', 'premium'].includes(company.value?.plan?.type || 'free'))

  async function fetchWebsiteForClient(path) {
    return new Promise((resolve, reject) => {
      try {
        if (path && !website.value.url) {
          getFirebaseCollection('website', async (data) => {
            for (const doc of data) {
              website.value = await updateWebsiteV2(doc, firebaseAuth.currentUser)
            }
            resolve(website.value) // Resolve the promise with the data
          }, '', '', [
            {
              field: 'url',
              operator: '==',
              value: path
            }
          ])
        } else {
          resolve(null) // Resolve with null if uid is falsy
        }
      } catch (e) {
        reject(e) // Reject the promise with the error
      }
    })
  }

  async function fetchCompanyProfile(uid) {
    if (!uid) throw new Error('User ID is required');

    try {
      const data = await getFirebaseData('companies', `${uid}`, '', '')
      company.value = data.data
    } catch (e) {
      throw new Error('Error in fetchCompanyProfile: ' + e.message);
    }
  }

  async function fetchUserProfile(uid) {
    if (!uid) throw new Error('User ID is required');

    try {
      const data = await getFirebaseData('users', `${uid}`, '', '')
      user.value = data.data
    } catch (e) {
      throw new Error('Error in fetchUserProfile: ' + e.message);
    }
  }

  function $reset() {
    website.value = cloneDeep(websiteRef)
  }


  return {
    website,
    company,
    user,
    isPremiumWeb,
    fetchWebsiteForClient,
    fetchUserProfile,
    fetchCompanyProfile,
    $reset
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useWebsiteStore, import.meta.hot))
}
