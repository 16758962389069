import {getRandomImage, randomTheme} from '@/utils/website.js';

async function updateWebsiteV2(website) {
  if (website.version !== 'v2') {
    return {
      ...website,
      url: website.url,
      version: 'v2',
      company_id: website.company.id,
      theme: randomTheme(website.colorTheme),
      createdAt: Date.now(),
      hero: {
        ...website.hero,
        image: getRandomImage()
      },
      cta: {
        ...website.homeCTA
      },
      gallery: {
        ...website.gallery,
        images: website.images
      },
      contact: {
        ...website.contactPageHeading
      },
      services: {
        ...website.imageSplit,
        body: '',
        image: getRandomImage(),
        services: [...website.imageSplit.bullets]
      },
      reviews: {
        title: 'Recent Reviews',
        headline: 'See what people have to say', ...website.reviews,
        image: getRandomImage(),
        reviews: website.reviews.reviews
      },
      faq: {
        ...website.faq.header,
        sections: [...website.faq.sections]
      }
    }
  } else {
    return website
  }
}


export {updateWebsiteV2}
