/** Import necessary Firebase SDKs and third-party libraries. */
import {initializeApp} from 'firebase/app'
import {getAuth} from 'firebase/auth'
import {doc as fbDoc, getFirestore} from 'firebase/firestore'
import {getStorage} from 'firebase/storage'
import {getFunctions, httpsCallable} from 'firebase/functions'
/**
 * Geofire
 * @see {link https://firebase.google.com/docs/firestore/solutions/geoqueries}
 * */

/**
 * Firebase configuration object for live environment.
 */
const firebaseConfig = {
  apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
  authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
  databaseURL: import.meta.env.VITE_FIREBASE_DATABASE_URL,
  projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
  storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
  appId: import.meta.env.VITE_FIREBASE_APP_ID,
  measurementId: import.meta.env.VITE_FIREBASE_MEASUREMENT_ID
}
/**
 * Initialize the Firebase app.
 */
const app = initializeApp(firebaseConfig)

/**
 *  Configures the Firebase firestore instance based on the platform.
 */
const firebaseDbConfig = () => {
  return getFirestore(app)

}
const firebaseDb = firebaseDbConfig()

/**
 *  Configures the Firebase Auth instance based on the platform.
 */
const firebaseAuthConfig = () => {
  return getAuth()
}
const firebaseAuth = firebaseAuthConfig()

const functions = getFunctions(app)
const storage = getStorage()

// const analytics = getAnalytics(app);

export {
  firebaseAuth,
  firebaseDb,
  functions,
  httpsCallable,
  storage,
  fbDoc
}
