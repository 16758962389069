import {colors} from '@/utils/constants.js';

const website = {
  url: "",
  company_id: "",
  theme: randomTheme(),
  createdAt: 0,
  version: 'v2',

  hero: {
    headline: "Professional Painting Services",
    body: "Never underestimate the power of clean lines, fresh paint, and a dedicated team.",
    image: getRandomImage(),
  },

  about: {
    title: "About Us",
    headline: "Quality you can count on",
    body: "Painting is much more than color application, its a way to express your personality, and more so, it’s a way to protect your investment.",
  },

  gallery: {
    title: "Gallery",
    headline: "Quality Craftsmanship",
    body: "We take pride in our work, our experience and our punctuality. We take every job seriously, no matter the size.",
    images: [],
  },

  contact: {
    title: "Reach Out to Us",
    headline: "We are here to listen and assist",
    body: "Painting goes beyond colors; it is an artistic expression of your personality and a means to safeguard your investment. Let us guide you through the process.",
    image: getRandomImage(),
  },

  cta: {
    title: "Ready to freshen up your space?",
    headline: "Schedule a free estimate today",
  },

  services: {
    title: "Our Services",
    headline: "More Than Painting",
    body: "Painting is much more than color application, its a way to express your personality, and more so, it’s a way to protect your investment.",
    image: getRandomImage(),
  },

  reviews: {
    title: "Recent Reviews",
    headline: "See what people have to say",
    reviews: [
      {
        body: "I can't recommend Premium Paint enough. They just finished painting our house and did an outstanding job. They stayed on budget and time. The site manger was GREAT. He was very personable, communicated with us often, was polite, COVID conscious and kept his crew on task.",
        name: "mac",
        rating: 5,
        role: "Denver, Co",
      },
      {
        body: "Professional work and professional service. The job started and ended on schedule and on budget. Work is meticulous. Team was communicative, polite, made great recommendations from start to finish. Expect high quality work and products from Premium Paint.",
        name: "alx.",
        rating: 5,
        role: "Denver, Co",
      },
      {
        body: "I researched a number of different companies and most others only provided verbal estimates and warranties. I appreciated the written and detailed scope of work from Premium Paint and the end result did not disappoint. They painted my exterior home in just a few days and cant recommend their expertise enough!",
        name: "jami",
        rating: 5,
        role: "Denver, Co",
      },
    ],
  },

  faq: {
    title: "We have Answers",
    headline: "Information is key",
    body: "Here are some of out frequently asked questions.",
    sections: [
      {
        title: "Why should I choose Premium Paint?",
        body: "We provide high quality workmanship and reliable painters. We also place an emphasis on customer service and quick communication. This philosophy has helped us grow into one of the most well respected painting companies",
      },
      {
        title:
          "We are on a budget, do you offer cost comparisons for estimates?",
        body: "Yes, We can easily send over multiple quotes so you can compare pricing options based on scope of work. Thinking about painting walls, trim, and ceilings but not sure if they all fit in the budget? Not sure if you can splurge on a new color for the doors? We will break down the costs of some of these add ons so you can pick the scope of work that fits your budget. An important note: Typically downgrading a paint will not make a huge difference in pricing, and we do not recommend going with budget paints as you will end up spending more on labor. Also, you will need to re-paint sooner because a lesser product is less durable",
      },
      {
        title: "Does you provide paint and supplies?",
        body: "Yes! To ensure quality control, we supplies all the necessary products for each project. We understand that every project is unique, and that is why our Paint Specialists choose the best products for your project and your home. ",
      },
      {
        title: "How often should I have the exterior of my house painted?",
        body: "Weather can be harsh on homes and paint is the first line of protection. Take a look at your window sills or the area of the house where siding meets the roof— these are some of the first locations to show signs of deterioration. If paint is visibly cracked, peeling, or in poor condition, it is past due and we recommend getting a free estimate.",
      },
      {
        title: "How long will exterior paint last?",
        body: "This question depends on many factors including materials, current condition, and exposure to the elements. For example: the color on the sunny side of the house fade faster especially with dark colors, wood deteriorates faster than brick, or the weather was extremely harsh the last few winters. Although there is no specific answer, below is a general guideline for exterior paint. Wood: 5-7 years, Brick/Stucco: 10 years, Vinyl/Aluminum: 10+ years",
      },
      {
        title: "What areas do you service?",
        body: "Our typical service area is within a 20 mile range from our headquarters. We are more than happy to provide more information for projects located outside of this area on a case by case basis. Email us as we take on all jobs on a case-by-case basis.",
      },
    ],
  },
};

function randomTheme(theme) {
  const themeToColor = {
    1: 'blue',
    2: 'sky',
    3: 'amber',
    4: 'teal',
    5: 'indigo',
    6: 'orange'
  }
  let newColor = null
  if (theme) {
    newColor = themeToColor[theme]
  }
  if (newColor) {
    return newColor
  } else {
    return getRandomColor()
  }
}

function generateRandomNumber(length) {
  return Math.floor(Math.random() * length);
}

function getRandomColor() {
  const index = generateRandomNumber(colors.length)
  return colors[index];
}

function getRandomImage() {
  const number = generateRandomNumber(71)
  return `img-${number}`;
}

export {website, generateRandomNumber, getRandomColor, getRandomImage, randomTheme}
