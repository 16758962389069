<script setup>
import {computed, ref} from 'vue'
import {XMarkIcon} from '@heroicons/vue/24/outline'
import {Dialog, DialogPanel, TransitionChild, TransitionRoot} from '@headlessui/vue'

const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false
  },
  position: {
    type: String,
    default: 'center'
  },
  fullscreen: {
    type: Boolean,
    default: false
  },
  title: {
    type: String,
    default: ''
  },
  text: {
    type: String,
    default: ''
  },
  closeButton: {
    type: Boolean,
    default: false
  },
  background: {
    type: String,
    default: 'bg-white dark:bg-gray-950'
  },
  width: {
    type: String,
    default: ''
  }
})
const emit = defineEmits(['update:modelValue', 'close'])

const focusButton = ref(null)

const popup = computed(() => ['center', 'top', 'bottom'].includes(props.position))

const isOpen = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    if (!value) emit('close')
    emit('update:modelValue', value)
  }
})

const wrapper = computed(() => {
  const positions = {
    center: 'items-center justify-center text-center',
    top: 'items-start justify-center text-center',
    bottom: 'items-end justify-center text-center',
    left: 'pointer-events-none fixed inset-y-0 left-0 sm:mr-10 h-screen',
    right: 'pointer-events-none fixed inset-y-0 right-0 sm:pl-10'
  }
  const padding = popup.value && !props.fullscreen ? 'p-4 sm:p-0' : null
  return [positions[props.position], padding]
})

const dialog = computed(() => {
  const height = props.fullscreen ? 'h-screen max-h-[100svh]' : null
  const round = popup.value && !props.fullscreen ? 'rounded-lg my-8' : null
  const padding = popup.value && !props.fullscreen ? 'pt-6' : null
  const width = props.width ? props.width : !popup.value ? 'w-[80vw] max-w-md lg:min-w-xl' : 'w-full max-w-xl'
  return [height, padding, round, width, props.background]
})

const transition = computed(() => {
  const popupTransition = {
    enter: 'ease-in duration-200',
    enterFrom: 'opacity-0 scale-95',
    enterTo: 'opacity-100 scale-100',
    leave: 'ease-out duration-200',
    leaveFrom: 'opacity-100 translate-y-0 sm:scale-100',
    leaveTo: 'opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
  }
  /*  const slideUp = {
      enter: 'transform transition ease-in-out duration-500 sm:duration-700',
      enterFrom: 'translate-y-[200%]',
      enterTo: 'translate-y-0',
      leave: 'transform transition ease-in-out duration-500 sm:duration-700',
      leaveFrom: 'translate-y-0',
      leaveTo: 'translate-y-[200%]'
    }*/
  const slideRight = {
    enter: 'transform transition ease-in-out duration-300 sm:duration-500',
    enterFrom: 'translate-x-full',
    enterTo: 'translate-x-0',
    leave: 'transform transition ease-in-out duration-300 sm:duration-500',
    leaveFrom: 'translate-x-0',
    leaveTo: 'translate-x-full'
  }
  const slideLeft = {
    enter: 'transform transition ease-in-out duration-300 sm:duration-500',
    enterFrom: '-translate-x-full',
    enterTo: 'translate-x-0',
    leave: 'transform transition ease-in-out duration-300 sm:duration-500',
    leaveFrom: 'translate-x-0',
    leaveTo: '-translate-x-full'
  }

  const position = props.position
  return position === 'right' ? slideRight : position === 'left' ? slideLeft : popupTransition
})
</script>

<template>
  <TransitionRoot
    :show="isOpen"
    as="template"
  >
    <Dialog
      :initial-focus="focusButton"
      as="div"
      class="relative z-40"
      @close="isOpen = false"
    >
      <!-- overlay -->
      <TransitionChild
        as="template"
        enter="ease-in duration-200"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-out duration-100"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-gray-900/75 backdrop-blur-sm transition-opacity" />
      </TransitionChild>

      <!-- dialog wrapper -->
      <div class="fixed inset-0 z-40 w-screen overflow-hidden max-h-[100svh] ">
        <div :class="[wrapper, 'flex max-w-full min-h-full max-h-screen']">
          <TransitionChild
            :enter="transition.enter"
            :enter-from="transition.enterFrom"
            :enter-to="transition.enterTo"
            :leave="transition.leave"
            :leave-from="transition.leaveFrom"
            :leave-to="transition.leaveTo"
            as="template"
          >
            <DialogPanel
              :class="[dialog, 'pointer-events-auto transform overflow-hidden bg-white pt-2 px-4 pb-4 text-left shadow-xl transition-all w-full max-h-[100svh]']"
            >
              <!-- invisible focus -->
              <button
                ref="focusButton"
                class="hidden"
              />
              <!-- Close button -->
              <div
                v-if="props.closeButton"
                :class="[!popup && 'safe-padding-top', 'absolute right-2 top-2 z-50']"
              >
                <button @click="isOpen = false">
                  <span class="sr-only">Close panel</span>
                  <XMarkIcon
                    aria-hidden="true"
                    class="h-5 w-5"
                  />
                </button>
              </div>

              <div
                :class="[!popup ? 'safe-padding' : null, 'flex h-full flex-col overflow-y-scroll max-h-[100vh] md:max-h-[calc(100vh_-_8rem)] relative scroll-bar--none bg-white dark:bg-gray-950']"
              >
                <div
                  v-if="props.title || props.text"
                  class="text-center mt-2"
                >
                  <p
                    v-if="props.title"
                    class="text-base/6 font-semibold"
                  >
                    {{ props.title }}
                  </p>

                  <p
                    v-if="props.text"
                    class="text-sm text-gray-500 my-2"
                  >
                    {{ props.text }}
                  </p>
                </div>

                <!-- Default Slot -->
                <div>
                  <slot />
                </div>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
